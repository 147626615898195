var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "container"
  }, [_c('el-form', {
    staticStyle: {
      "width": "50%"
    },
    attrs: {
      "label-position": _vm.labelPosition,
      "label-width": "80px",
      "model": _vm.formLabelAlign,
      "size": "mini"
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "名称"
    }
  }, [_c('el-input', {
    model: {
      value: _vm.formLabelAlign.knowledgeName,
      callback: function ($$v) {
        _vm.$set(_vm.formLabelAlign, "knowledgeName", $$v);
      },
      expression: "formLabelAlign.knowledgeName"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "等级"
    }
  }, [_c('el-input', {
    attrs: {
      "disabled": ""
    },
    model: {
      value: _vm.formLabelAlign.level,
      callback: function ($$v) {
        _vm.$set(_vm.formLabelAlign, "level", $$v);
      },
      expression: "formLabelAlign.level"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "路径"
    }
  }, [_c('el-input', {
    attrs: {
      "disabled": ""
    },
    model: {
      value: _vm.formLabelAlign.parentPath,
      callback: function ($$v) {
        _vm.$set(_vm.formLabelAlign, "parentPath", $$v);
      },
      expression: "formLabelAlign.parentPath"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "重要性"
    }
  }, [_c('el-input', {
    attrs: {
      "disabled": ""
    },
    model: {
      value: _vm.formLabelAlign.importance,
      callback: function ($$v) {
        _vm.$set(_vm.formLabelAlign, "importance", $$v);
      },
      expression: "formLabelAlign.importance"
    }
  })], 1), _c('el-form-item', [_c('el-button', {
    attrs: {
      "type": "primary",
      "size": "mini"
    },
    on: {
      "click": function ($event) {
        return _vm.add();
      }
    }
  }, [_vm._v(" 提交 ")]), _c('el-button', {
    attrs: {
      "type": "primary",
      "size": "mini"
    },
    on: {
      "click": function ($event) {
        return _vm.getKnowledgeList();
      }
    }
  }, [_vm._v(" 刷新 ")])], 1)], 1), _c('el-tree', {
    attrs: {
      "data": _vm.treeData,
      "node-key": "id",
      "expand-on-click-node": false,
      "props": _vm.defaultProps
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function ({
        node,
        data
      }) {
        return _c('span', {
          staticClass: "custom-tree-node"
        }, [_c('span', [_vm._v(_vm._s(data.name))]), _c('span', [_c('el-button', {
          attrs: {
            "type": "text",
            "size": "mini"
          },
          on: {
            "click": () => _vm.append(node, data)
          }
        }, [_vm._v(" 添加 ")]), _c('el-button', {
          attrs: {
            "type": "text",
            "size": "mini"
          },
          on: {
            "click": () => _vm.edit(node, data)
          }
        }, [_vm._v(" 编辑 ")]), _c('el-button', {
          attrs: {
            "type": "text",
            "size": "mini"
          },
          on: {
            "click": () => _vm.remove(node, data)
          }
        }, [_vm._v(" 删除 ")])], 1)]);
      }
    }])
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };