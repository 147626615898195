export default {
  name: 'ManageKnowledge',
  data() {
    return {
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      treeData: [],
      labelPosition: 'right',
      formLabelAlign: {
        importance: '',
        knowledgeName: '',
        level: '',
        parentPath: ''
      }
    };
  },
  created() {
    this.getKnowledgeList();
  },
  methods: {
    async getKnowledgeList() {
      // 获取知识点树
      const result = await this.Api.databaseManage.getKnowledgeTree({});
      this.treeData = result.data;
    },
    append(data, node) {
      this.formLabelAlign.level = node.level;
      let parent = data.parent.data;
      console.log("🚀 ~ append ~ parent:", parent);
      if (data.level == 1) {
        this.formLabelAlign.parentPath = '';
        this.formLabelAlign.parentCode = '';
      } else {
        this.formLabelAlign.parentPath = parent.path;
        this.formLabelAlign.parentCode = parent.code;
      }
      if (data.level == 4) {
        this.formLabelAlign.importance = 'Z';
      } else {
        this.formLabelAlign.importance = '';
      }
      console.log(data, node);
    },
    remove(data, node) {
      console.log(data, node);
      this.$confirm('此操作将永久删除该知识点, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let result = await this.Api.databaseManage.delKnowledge({
          knowledgeId: Number(data.id)
        });
        if (result.code != 200) {
          this.$message.error(result.message);
          return;
        }
        this.$message.success('删除成功');
        // this.getKnowledgeList();
      }).catch(() => {}); // 取消不做任何操作 
    },
    async edit() {
      // let data{ "knowledgeId":1780520177596334081, "knowledgeName":"三级CSACSA知识点B", "importance":"X" }
      const result = await this.Api.databaseManage.updateKnowledge(this.formLabelAlign);
      if (result.code != 200) {
        this.$message.error(result.message);
        return;
      }
      this.$message.success('添加成功');
      this.formLabelAlign = {
        importance: '',
        knowledgeName: '',
        level: '',
        parentPath: ''
      };
      this.getKnowledgeList();
    },
    async add() {
      const result = await this.Api.databaseManage.addKnowledge(this.formLabelAlign);
      if (result.code != 200) {
        this.$message.error(result.message);
        return;
      }
      this.$message.success('添加成功');
      this.formLabelAlign = {
        importance: '',
        knowledgeName: '',
        level: '',
        parentPath: ''
      };
      // this.getKnowledgeList();
    }
  }
};